import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { Label as LabelBase } from '@/components/FormControls'
import { useTheme } from '@/theme'

type RenderProps = {
  isCollapsed: boolean
}

type Props = {
  canCollapse?: boolean
  forceBottomSpacing?: boolean
  isCollapsedInitially?: boolean
  label?: ReactNode
  render: (props: RenderProps) => ReactNode
}

const FilterSection = ({
  canCollapse,
  forceBottomSpacing,
  isCollapsedInitially,
  label,
  render,
}: Props) => {
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState<boolean>(!isCollapsedInitially)

  return (
    <Wrapper forceBottomSpacing={forceBottomSpacing}>
      {canCollapse ? (
        <TitleRow
          justifyContent="space-between"
          onClick={() => setIsOpen((x) => !x)}
        >
          {label && <Label>{label}</Label>}
          {canCollapse && (
            <FontAwesomeIcon
              color={theme.palette.text.light}
              icon={isOpen ? 'angle-down' : 'angle-left'}
              fixedWidth
            />
          )}
        </TitleRow>
      ) : (
        <TitleRow disabled>{label && <Label>{label}</Label>}</TitleRow>
      )}
      {render({ isCollapsed: !isOpen })}
    </Wrapper>
  )
}

export default FilterSection

////////////

const Wrapper = styled.section<{ forceBottomSpacing?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
    padding-bottom: ${theme.spacing.gutter};
  `}

  @media (max-width: 900px) {
    margin-bottom: 0;
  }

  ${({ forceBottomSpacing }) =>
    forceBottomSpacing
      ? ''
      : css`
          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
          }
        `}
`

const Label = styled(LabelBase)`
  color: inherit;
  width: 100%;
`

interface TitleRowProps {
  disabled?: boolean
}

const TitleRow = styled(FlexRow)<TitleRowProps>`
  border-radius: 4px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin: -${theme.spacing.gu(0.5)}rem -${theme.spacing.gu(1)}rem
      ${theme.spacing.gutterSmall};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem
      ${theme.spacing.gu(0.5)}rem;
  `}

  ${({ disabled, theme }) =>
    !disabled &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${theme.palette.smoke.lighter};
        color: ${theme.palette.text.main};
      }

      &:active {
        background-color: ${theme.palette.smoke.light};
        color: ${theme.palette.text.dark};
      }

      @media (max-width: 900px) {
        &:hover,
        &:active {
          background-color: transparent;
          color: ${theme.palette.text.light};
        }
      }
    `}
`
