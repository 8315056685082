import React from 'react'

import {
  DataSearch,
  DateRange,
  DimensionSelector,
  DynamicRangeSlider,
  MultiList,
} from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { DimensionTarget } from '~generated-types'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { useTheme } from '@/theme'
import { withFilterSettings } from '@/components/ElasticFilterSearchList/enhancers'

import { FiltersSettings } from './FiltersSettings'

export type FilterOption = {
  disabled: boolean
  selected: boolean
  value: string
}

type ListFiltersProps = {
  componentIds: Record<string, string>
  getDefaultQuery: () => Record<string, unknown>
  URLParams?: boolean
  filterNames: string[]
  searchContext: 'enrollments' | 'sales'
  filters: FilterOption[]
  onFilterSettingsChange: () => void
}

const ListFilters = ({
  componentIds,
  getDefaultQuery,
  URLParams,
  filters,
  searchContext,
  onFilterSettingsChange,
}: ListFiltersProps) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const STATE_DEFAULT = ['OPEN', 'CONFIRMED']

  const contextNamespace =
    searchContext === 'sales' ? 'SalesSearchList' : 'EnrollmentsSearchList'

  const renderFilter = (filter: string) => {
    switch (filter) {
      case 'estimatedDates':
        return (
          <FilterSection
            canCollapse
            label={
              <T>{`${contextNamespace}:ListHeader.filter.estimatedDates`}</T>
            }
            render={({ isCollapsed }) => (
              <DateRange
                componentId={componentIds.DATES}
                dataField={{
                  end: 'searchDates.end',
                  start: 'searchDates.start',
                }}
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  `${contextNamespace}:ListHeader.estimatedDates`,
                  language
                )}
                hideClearButton
                isCollapsed={isCollapsed}
                URLParams={!!URLParams}
              />
            )}
          />
        )
      case 'type':
        return (
          <FilterSection
            canCollapse
            label={<T>SalesSearchList:ListHeader.filter.type</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.TYPE}
                dataField="facet.name"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'SalesSearchList:ListHeader.type',
                  language
                )}
                isCollapsed={isCollapsed}
                showCheckbox
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'saleState':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>{`${contextNamespace}:ListHeader.filter.saleState`}</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.STATE}
                dataField="state"
                defaultQuery={getDefaultQuery}
                defaultValue={STATE_DEFAULT}
                filterLabel={translate(
                  `${contextNamespace}:ListHeader.filter.saleState`,
                  language
                )}
                l10nPrefix="SalesDetails:Lifecycle.state"
                isCollapsed={isCollapsed}
                showCheckbox
                showMissing
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'participants':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>SalesSearchList:ListHeader.filter.participants</T>}
            render={({ isCollapsed }) => (
              <DynamicRangeSlider
                componentId={componentIds.PARTICIPANTS}
                dataField="participants"
                filterLabel={translate(
                  'SalesSearchList:ListHeader.participants',
                  language
                )}
                includeNullValues
                isCollapsed={isCollapsed}
                rangeLabels={(min: number, max: number) => ({
                  end: `${max} ${translate(
                    'SalesSearchList:ListHeader.filter.participantsLabel',
                    language
                  )}`,
                  start: `${min} ${translate(
                    'SalesSearchList:ListHeader.filter.participantsLabel',
                    language
                  )}`,
                })}
                URLParams={!!URLParams}
              />
            )}
          />
        )
      case 'seller':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>SalesSearchList:ListHeader.filter.seller</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.SELLER}
                dataField="seller"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'SalesSearchList:ListHeader.seller',
                  language
                )}
                isCollapsed={isCollapsed}
                missingLabel={translate(
                  'SalesSearchList:ListHeader.sellerMissing',
                  language
                )}
                showCheckbox
                showMissing
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'dimensions':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>FrontDesk:DailyCustomerList.dimensions.title</T>}
            render={({ isCollapsed }) => (
              <DimensionSelector
                componentId={componentIds.DIMENSIONS}
                defaultQuery={getDefaultQuery}
                dimensionTarget={DimensionTarget.SalesListSearch}
                filterLabel={translate(
                  'FrontDesk:DailyCustomerList.dimensions.title',
                  language
                )}
                hideEmpty={false}
                hideCounts
                isCollapsed={isCollapsed}
                URLParams
              />
            )}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <FlexRow justifyContent="space-between" alignItems="center">
        <FontWeight bold style={{ color: theme.palette.text.light }}>
          <T>{`${contextNamespace}:ListControls.search`}</T>
        </FontWeight>

        <FiltersSettings
          filters={filters}
          nameSpace="SalesSearchList"
          onOptionClick={onFilterSettingsChange}
        />
      </FlexRow>
      <FilterSection
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH}
            dataField={['customerName', 'name', 'orderNumber.text']}
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              `${contextNamespace}:ListControls.search`,
              language
            )}
            placeholder={translate(
              `${contextNamespace}:ListControls.searchPlaceholder`,
              language
            )}
            queryFormat={'and'}
            showClear
            showIcon
            URLParams
          />
        )}
      />

      <FilterSection
        label={<T>SalesSearchList:ListControls.searchParticipants</T>}
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH_PARTICIPANTS}
            dataField="participantNames"
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'SalesSearchList:ListControls.searchParticipants',
              language
            )}
            queryFormat="and"
            placeholder={translate(
              'SalesSearchList:ListControls.searchParticipants',
              language
            )}
            showClear
            showIcon
            URLParams
          />
        )}
      />

      {searchContext === 'enrollments' && (
        <FilterSection
          label={<T>EnrollmentsSearchList:ListControls.searchEvents</T>}
          render={() => (
            <DataSearch
              autosuggest={false}
              componentId={componentIds.SEARCH_EVENTS}
              dataField={[
                'contextEvent.name',
                'contextEvent.customerName',
                'contextEvent.number.text',
              ]}
              defaultQuery={getDefaultQuery}
              filterLabel={translate(
                'EnrollmentsSearchList:ListControls.search',
                language
              )}
              placeholder={translate(
                `EnrollmentsSearchList:ListControls.searchEventsPlaceholder`,
                language
              )}
              showClear
              showIcon
              URLParams
            />
          )}
        />
      )}

      {filters
        .filter(({ selected }) => selected)
        .map((filter) => (
          <React.Fragment key={filter.value}>
            {renderFilter(filter.value)}
          </React.Fragment>
        ))}
    </>
  )
}

export default withFilterSettings(ListFilters)
