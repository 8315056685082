import { T } from '@/modules/Language'

import ListFilters from './components/ListFilters'
import SalesSearchList from './SalesSearchList'

const ComponentIds = Object.freeze({
  DATES: 'dates',
  DIMENSIONS: 'dimensions',
  LIST: 'page',
  PARTICIPANTS: 'participants',
  SEARCH: 'sales-search',
  SEARCH_PARTICIPANTS: 'participants-search',
  SELLER: 'seller',
  STATE: 'state',
  TYPE: 'type',
})

const FILTERS = [
  'estimatedDates',
  'type',
  'saleState',
  'participants',
  'seller',
  'dimensions',
]

type SalesSearchListProps = {
  hideFilters?: boolean
}

export const SalesSearchListContainer = ({
  hideFilters,
}: SalesSearchListProps) => {
  const defaultQueryFn = () => ({})

  return (
    <SalesSearchList
      hideFilters={hideFilters}
      componentIds={ComponentIds}
      listTitle={<T>SalesSearchList:title</T>}
      defaultQueryFn={defaultQueryFn}
      renderFilters={() => (
        <ListFilters
          searchContext="sales"
          filterNames={FILTERS}
          componentIds={ComponentIds}
          getDefaultQuery={defaultQueryFn}
          URLParams
        />
      )}
    />
  )
}

export default SalesSearchListContainer
