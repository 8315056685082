import moment, { HTML5_FMT } from 'moment'
import styled, { css } from 'styled-components/macro'
import { UIEvent, useRef, useState } from 'react'
import { SelectedFilters } from '@appbaseio/reactivesearch'

import {
  DataSearch,
  DateRange,
  DimensionSelector,
  DynamicRangeSlider,
  MultiList,
} from '@/components/Reactivesearch'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { DimensionTarget } from '~generated-types'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { IconButton as IconButtonBase } from '@/components/ExtraButtons'

import { ModalSheet, ModalSheetOverlay, ScrollIndicator } from '../components'

type Props = {
  componentIds: {
    [key: string]: string
  }
  totalResults: number
}

const STATE_DEFAULT = ['OPEN', 'CONFIRMED']

export const SalesListFilters = ({ componentIds, totalResults }: Props) => {
  const { language } = useLanguageContext()

  const scrollRef = useRef<HTMLDivElement>(null)

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [scrollTop, setScrollTop] = useState<number>(0)

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    if (scrollRef && scrollRef.current === event.target) {
      setScrollTop(event.currentTarget.scrollTop)
    }
  }

  const t = (path: string) => translate(path, language)

  return (
    <>
      <IconButton
        color="transparent"
        noNudge
        icon="filter"
        onClick={() => setModalOpen(true)}
      />

      <ModalSheet justifyContent="flex-start" isOpen={isModalOpen}>
        <Header alignItems="center" justifyContent="space-between">
          <FlexRow>
            <IconButton
              color="transparent"
              noNudge
              icon="arrow-left"
              onClick={() => setModalOpen(false)}
            />
            <Title>
              <T>Mobile:salesList.filters</T>
            </Title>
          </FlexRow>

          <SelectedFilters
            render={({ clearValues, selectedValues }) => {
              const selected = Object.keys(selectedValues)
                .map((key) => ({ ...selectedValues[key], key }))
                .filter(
                  ({ showFilter, value }) =>
                    showFilter &&
                    (Array.isArray(value) ? !!value.length : !!value)
                )

              return (
                !!selected.length && (
                  <IconButton
                    color="transparent"
                    noNudge
                    icon="filter-circle-xmark"
                    onClick={clearValues}
                  />
                )
              )
            }}
          />
        </Header>

        <ScrollIndicator hasScrolled={!!scrollTop} zIndex={502} />

        <Content onScroll={onScroll} ref={scrollRef}>
          <FilterSection
            label={<T>SalesSearchList:ListControls.search</T>}
            render={() => (
              <DataSearch
                autosuggest={false}
                componentId={componentIds.SEARCH}
                dataField={['customerName', 'name', 'orderNumber.text']}
                filterLabel={t('SalesSearchList:ListControls.search')}
                isMobile
                placeholder={t(
                  'SalesSearchList:ListControls.searchPlaceholder'
                )}
                queryFormat="and"
                showClear
                showIcon
                URLParams
              />
            )}
          />

          <FilterSection
            label={<T>SalesSearchList:ListControls.searchParticipants</T>}
            render={() => (
              <DataSearch
                autosuggest={false}
                componentId={componentIds.SEARCH_PARTICIPANTS}
                dataField="participantNames"
                filterLabel={t(
                  'SalesSearchList:ListControls.searchParticipants'
                )}
                isMobile
                queryFormat="and"
                placeholder={t(
                  'SalesSearchList:ListControls.searchParticipants'
                )}
                showClear
                showIcon
                URLParams
              />
            )}
          />

          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>SalesSearchList:ListHeader.filter.estimatedDates</T>}
            render={({ isCollapsed }) => (
              <DateRange
                componentId={componentIds.DATES}
                dataField={{
                  end: 'searchDates.end',
                  start: 'searchDates.start',
                }}
                defaultValue={{
                  end: moment().format(HTML5_FMT.DATE),
                  start: moment().format(HTML5_FMT.DATE),
                }}
                filterLabel={t(`SalesSearchList:ListHeader.estimatedDates`)}
                hideClearButton
                isCollapsed={isCollapsed}
                URLParams
              />
            )}
          />

          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>SalesSearchList:ListHeader.filter.type</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.TYPE}
                dataField="facet.name"
                filterLabel={t('SalesSearchList:ListHeader.type')}
                isCollapsed={isCollapsed}
                showCheckbox
                showSearch={false}
                URLParams
              />
            )}
          />

          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>SalesSearchList:ListHeader.filter.saleState</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.STATE}
                dataField="state"
                defaultValue={STATE_DEFAULT}
                filterLabel={t(`SalesSearchList:ListHeader.filter.saleState`)}
                l10nPrefix="SalesDetails:Lifecycle.state"
                isCollapsed={isCollapsed}
                showCheckbox
                showMissing
                showSearch={false}
                URLParams
              />
            )}
          />

          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>SalesSearchList:ListHeader.filter.participants</T>}
            render={({ isCollapsed }) => (
              <DynamicRangeSlider
                componentId={componentIds.PARTICIPANTS}
                dataField="participants"
                filterLabel={t('SalesSearchList:ListHeader.participants')}
                includeNullValues
                isCollapsed={isCollapsed}
                rangeLabels={(min: number, max: number) => ({
                  end: `${max} ${t(
                    'SalesSearchList:ListHeader.filter.participantsLabel'
                  )}`,
                  start: `${min} ${t(
                    'SalesSearchList:ListHeader.filter.participantsLabel'
                  )}`,
                })}
                URLParams
              />
            )}
          />

          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>SalesSearchList:ListHeader.filter.seller</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.SELLER}
                dataField="seller"
                filterLabel={t('SalesSearchList:ListHeader.seller')}
                isCollapsed={isCollapsed}
                missingLabel={t('SalesSearchList:ListHeader.sellerMissing')}
                showCheckbox
                showMissing
                showSearch={false}
                URLParams
              />
            )}
          />

          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>FrontDesk:DailyCustomerList.dimensions.title</T>}
            render={({ isCollapsed }) => (
              <DimensionSelector
                componentId={componentIds.DIMENSIONS}
                defaultQuery={() => ({})}
                dimensionTarget={DimensionTarget.SalesListSearch}
                filterLabel={t('FrontDesk:DailyCustomerList.dimensions.title')}
                hideEmpty={false}
                hideCounts
                isCollapsed={isCollapsed}
                URLParams
              />
            )}
          />
        </Content>

        <Footer alignItems="center">
          <Button onClick={() => setModalOpen(false)}>
            {translate('Mobile:salesList.showTotals', language, {
              count: totalResults,
            })}
          </Button>
        </Footer>
      </ModalSheet>

      <ModalSheetOverlay isOpen={isModalOpen} />
    </>
  )
}

////////////

const Button = styled.button`
  width: 100%;

  ${({ theme }) => css`
    font-weight: 600;
    border-radius: 8px;
    height: ${theme.spacing.gu(6)}rem;
    color: ${theme.palette.white};
    background: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
  `}
`

const Content = styled(FlexColumn)`
  flex: 1;
  width: 100%;
  overflow: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(3)}rem;
  `}
`

const Header = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(3)}rem
      ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem;
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const Footer = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
    border-top: 1px solid ${theme.palette.smoke.main};
  `}
`

const IconButton = styled(IconButtonBase)`
  justify-content: center;
  height: 32px;
  width: 32px;
  border: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}

  &&:hover, &&:focus, &&:active {
    background: transparent;
  }
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
